.habit {
  margin: 30px 0 0 115px;
  width: 60%;
}

.habit-title {
  margin: 50px 0 0 15px;
}

.enclose {
  max-width: 50%;
  display: inline;
  overflow: true;
}

.survey-footer {
  width: 100%;
  height: 6rem;
  background-color: white;
}
